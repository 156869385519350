<template>
  <div class="card">
    <div class="card-header pt-10 pb-10">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        เปลี่ยนรหัสผ่าน / Change Password
      </h3>
      <div class="card-toolbar"></div>
    </div>

    <div class="card-title row ms-6 me-5 mt-10">
      <div class="symbol symbol-100px symbol-circle mb-10">
        <img class="shadow-sm" alt="Logo" src="media/avatars/150-1.jpg" />
      </div>

      <div class="row mb-5">
        <div class="col-4 col-md-2 col-sm-1 mb-10">
          <div style="font-weight: bold; font-size: 14px">Username</div>
        </div>
        <div class="col-1 col-sm-1">
          <div>:</div>
        </div>
        <div class="col-7 col-md-9 col-sm-10 text-start">
          <div>{{ this.user.username }}</div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-4 col-md-2 col-sm-1 mb-10">
          <div style="font-weight: bold; font-size: 14px">บทบาท</div>
        </div>
        <div class="col-1 col-sm-1">
          <div>:</div>
        </div>
        <div class="col-7 col-md-9 col-sm-10 text-start">
          <div :class="classRole">
            {{ this.user.role }}
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div class="row">
          <div class="col">
            <div style="font-weight: bold; font-size: 14px">รหัสผ่านเก่า</div>
          </div>
          <div class="col d-flex justify-content-end align-items-center">
            <div
              v-if="spinning"
              class="spinner-border"
              role="status"
              style="color: #3699ff"
            ></div>
            <i
              v-if="isOldPass"
              :class="iconCheckOldPass"
              :style="styleIconCheckOldPass"
            ></i>
          </div>
        </div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12 mb-5">
            <div>
              <div style="position: relative">
                <input
                  @blur="onOldPassBlur(form.oldPass)"
                  @mouseleave="checkForm()"
                  v-model="form.oldPass"
                  @input="passwordChange(form.oldPass)"
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  class="form-control fadeIn third"
                  placeholder="Password"
                  required
                  maxlength="6"
                />
                <button
                  type="button"
                  class="fadeIn third"
                  @click.prevent="showPassword = !showPassword"
                  id="btnPass"
                  @mouseover="colorEyePass = 'color: white;'"
                  @mouseleave="colorEyePass = 'color: grey;'"
                  style="
                    cursor: pointer;
                    position: absolute;
                    top: 18%;
                    right: 3%;
                    border-radius: 55%;
                    border-color: transparent;
                  "
                >
                  <i class="far fa-eye" :style="colorEyePass"></i>
                </button>
              </div>
              <div class="row d-flex justify-space-between">
                <div class="col-4 text-start fadeIn third">
                  <span>{{ counterPass }} / 6</span>
                </div>
                <div class="col-8 text-end" style="color: #f1416c">
                  <span v-if="textPassError">{{ textPassError }}</span>
                </div>
              </div>
              <div
                v-if="!form.oldPass"
                class="invalid-feedback text-end pe-8 pb-3"
              >
                <span>กรุณากรอก</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">รหัสผ่านใหม่</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12 mb-5">
            <div>
              <div style="position: relative">
                <input
                  @mouseleave="checkForm()"
                  v-model="form.newPass"
                  @input="newPasswordChange(form.newPass)"
                  :type="showNewPassword ? 'text' : 'password'"
                  id="newPass"
                  class="form-control fadeIn third"
                  placeholder="Password"
                  required
                  maxlength="6"
                />
                <button
                  type="button"
                  class="fadeIn third"
                  @click.prevent="showNewPassword = !showNewPassword"
                  id="btnNewPass"
                  @mouseover="colorEyeNewPass = 'color: white;'"
                  @mouseleave="colorEyeNewPass = 'color: grey;'"
                  style="
                    cursor: pointer;
                    position: absolute;
                    top: 18%;
                    right: 3%;
                    border-radius: 55%;
                    border-color: transparent;
                  "
                >
                  <i class="far fa-eye" :style="colorEyeNewPass"></i>
                </button>
              </div>
              <div class="row d-flex justify-space-between">
                <div class="col-4 text-start fadeIn third">
                  <span>{{ counterNewPass }} / 6</span>
                </div>
                <div class="col-8 text-end pe-14" style="color: #f1416c">
                  <span v-if="textNewPassError">{{ textNewPassError }}</span>
                </div>
              </div>
              <div
                v-if="!form.newPass"
                class="invalid-feedback text-end pe-8 pb-3"
              >
                <span>กรุณากรอก</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">ยืนยันรหัสผ่านใหม่</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12 mb-5">
            <!-- <input
              @mouseleave="checkForm()"
              v-model="form.confirmNewPass"
              class="form-control"
            /> -->
            <div>
              <div style="position: relative">
                <input
                  @mouseleave="checkForm()"
                  v-model="form.confirmNewPass"
                  @input="newConfirmPasswordChange(form.confirmNewPass)"
                  :type="showConfirmNewPassword ? 'text' : 'password'"
                  id="confirmNewPass"
                  class="form-control fadeIn third"
                  placeholder="Password"
                  required
                  maxlength="6"
                />
                <button
                  type="button"
                  class="fadeIn third"
                  @click.prevent="
                    showConfirmNewPassword = !showConfirmNewPassword
                  "
                  id="btnConfirmNewPass"
                  @mouseover="colorEyeConfirmNewPass = 'color: white;'"
                  @mouseleave="colorEyeConfirmNewPass = 'color: grey;'"
                  style="
                    cursor: pointer;
                    position: absolute;
                    top: 18%;
                    right: 3%;
                    border-radius: 55%;
                    border-color: transparent;
                  "
                >
                  <i class="far fa-eye" :style="colorEyeConfirmNewPass"></i>
                </button>
              </div>
              <div class="row d-flex justify-space-between">
                <div class="col-4 text-start fadeIn third">
                  <span>{{ counterConfirmNewPass }} / 6</span>
                </div>
                <div class="col-8 text-end pe-14" style="color: #f1416c">
                  <span v-if="textConfirmNewError">{{
                    textConfirmNewError
                  }}</span>
                </div>
              </div>
              <div
                v-if="!form.confirmNewPass"
                class="invalid-feedback text-end pe-8 pb-3"
              >
                <span>กรุณากรอก</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="pt-0 mt-0" style="color: LightGrey" />

    <footer class="ms-6 mb-6 mt-2">
      <div class="card-toolbar row justify-content-center">
        <div class="col-sm-12">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary ms-3 me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit"
            :disabled="isEmptyForm ? true : loading ? true : false"
          >
            <div
              v-if="loading"
              class="spinner-grow spinner-grow-sm text-light"
              role="status"
            ></div>
            บันทึก
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            ยกเลิก
          </button> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import loginApi from "@/api/user/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  data: () => ({
    loading: false,
    spinning: false,
    isOldPass: false,
    isEmptyForm: true,

    iconCheckOldPass: "",
    styleIconCheckOldPass: "",

    showPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,

    counterPass: 0,
    textPassError: "",

    counterNewPass: 0,
    textNewPassError: "",

    counterConfirmNewPass: 0,
    textConfirmNewError: "",

    colorEyePass: "color: grey;",
    colorEyeNewPass: "color: grey;",
    colorEyeConfirmNewPass: "color: grey;",

    userId: "",
    user: [],
    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",
    form: {
      oldPass: "",
      newPass: "",
      confirmNewPass: "",
    },
  }),
  created() {
    this.getAllUser();
  },
  methods: {
    async getAllUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
        this.setClassRole();
      }
    },

    async submit() {
      if (
        !this.textPassError &&
        !this.textNewPassError &&
        !this.textConfirmNewError
      ) {
        const itemUpdate = {
          username: this.user.username,
          password: this.form.newPass,
        };

        this.loading = true;

        const response = await userApi.user.update(this.userId, itemUpdate);

        if (response.response_status === "SUCCESS") {
          setTimeout(() => {
            Swal.fire({
              icon: "success",
              title: "เปลี่ยนรหัสผ่านสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.loading = false;
              this.clearForm();
              this.styleIconCheckOldPass = "";
              this.isOldPass = false;
              this.signOut();
            });
          }, 1500);
        } else {
          setTimeout(() => {
            Swal.fire({
              icon: "error",
              title: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
              showConfirmButton: true,
              confirmButtonText: "ตกลง",
              timer: 3500,
            }).then(() => {
              this.loading = false;
              this.clearForm();
              this.styleIconCheckOldPass = "";
              this.isOldPass = false;
            });
          }, 1500);
        }
      }
    },

    async signOut() {
      await localStorage.clear();
      this.$router.push("/login");
    },

    setClassRole() {
      this.user["role"] === "SUPER ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-primary fw-bolder fs-6")
        : this.user["role"] === "ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-success fw-bolder fs-6")
        : this.user["role"] === "MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-warning fw-bolder fs-6")
        : this.user["role"] === "BRANCH MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-danger fw-bolder fs-6")
        : this.user["role"] === "STAFF"
        ? (this.classRole = "badge badge-lg badge-light-info fw-bolder fs-6")
        : (this.classRole =
            "badge badge-lg badge-light-success fw-bolder fs-6");
    },

    checkForm() {
      if (this.form.oldPass && this.form.newPass && this.form.confirmNewPass) {
        this.isEmptyForm = false;
      } else {
        this.isEmptyForm = true;
      }
    },

    clearForm() {
      this.form.oldPass = "";
      this.form.newPass = "";
      this.form.confirmNewPass = "";
    },

    // password change

    passwordChange(val) {
      if (val) {
        this.counterPass = val.length;

        if (val.length < 6) {
          this.textPassError = "กรุณากรอกให้ครบ 6 หลัก";

          this.passwordEmpty = true;

          // clear validate confirmPass
          this.textConfirmError = "";
          this.form.confirmPassword = "";
          this.couterConfirmPass = 0;
        } else {
          this.textPassError = "";
          this.passwordEmpty = false;
        }
      } else {
        this.counterPass = 0;
        this.passwordEmpty = true;
      }
    },

    newPasswordChange(val) {
      if (val) {
        this.counterNewPass = val.length;

        if (val.length < 6) {
          this.textNewPassError = "กรุณากรอกให้ครบ 6 หลัก";

          // this.showNewPassword = true;

          // clear validate confirmPass
          this.textConfirmNewError = "";
          this.form.confirmPassword = "";
          this.counterNewPass = 0;
        }
        if (val.length === 6) {
          if (val === this.form.oldPass) {
            this.textNewPassError = "รหัสผ่านใหม่ต้องไม่เหมือนรหัสผ่านเก่า";
            this.showNewPassword = false;
          } else {
            this.textNewPassError = "";
            this.showNewPassword = false;
          }
        }
      } else {
        this.counterNewPass = 0;
        this.showNewPassword = true;
      }
    },

    newConfirmPasswordChange(val) {
      if (val) {
        this.counterConfirmNewPass = val.length;

        if (val !== this.form.newPass) {
          this.textConfirmNewError = "กรุณากรอกให้ตรงกับรหัสผ่านใหม่";
        } else {
          this.textConfirmNewError = "";
        }
      } else {
        this.counterConfirmNewPass = 0;
      }
    },

    // check old password
    async onOldPassBlur(password) {
      if (password) {
        this.spinning = true;
        const loginItem = {
          username: this.user.username,
          password: password,
        };

        const response = await loginApi.login.login(loginItem);
        if (response.response_status === "SUCCESS") {
          this.spinning = false;
          this.isOldPass = true;
          this.iconCheckOldPass = "bi bi-check2-circle";
          this.styleIconCheckOldPass = "font-size: 20px; color: #1bc5bd";
        } else {
          this.spinning = false;
          this.isOldPass = true;
          this.iconCheckOldPass = "bi bi-x-circle";
          this.styleIconCheckOldPass = "font-size: 20px; color: #F1416C";
        }
      }
    },
  },
};
</script>
